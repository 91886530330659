import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mobile, desktop } from '@styles/global'
import useGenerateJumpLinks from '@utils/useGenerateJumpLinks'
import RichText from '@components/RichText'
import { Darr } from '@components/Svg'
import Section from '@components/Section'
// import dynamic from 'next/dynamic'
// const ResultDownload = dynamic(() => import('./ResultDownload'))

import loadable from '@loadable/component'
const ResultDownload = loadable(() => import('./ResultDownload'))

const Intro = ({ className, content }) => {
	const jumpLinks = useGenerateJumpLinks(content.modules)
	const scrollToId = (e, id) => {
		e.preventDefault()
		document.getElementById(id).scrollIntoView({
			behavior: 'smooth'
		})
	}
	return (
		<Wrap className={className}>
			<IntroCol>
				<Headline>{content.introduction?.headline}</Headline>
				<RichText content={content.introduction?.text}/>
				{content.section === 'checklist-results' && <ResultDownload />}
			</IntroCol>
			<JumpLinksCol>
				{jumpLinks?.length > 0 &&
					<JumpLinks>
						<JumpLinksHeading>Jump to</JumpLinksHeading>
						{jumpLinks.map(link => (
							<JumpLink 
								key={link.key} 
								className='sub' 
								onClick={e => scrollToId(e, link.hash)}
								href={'#' + link.hash}
							>
								<div>
									{link.title}
								</div>
								<DownArrow />
							</JumpLink>
						))}
					</JumpLinks>
				}
			</JumpLinksCol>
		</Wrap>
	)
}


const Wrap = styled(Section)`
	margin-bottom: var(--xxxxl);
	${mobile}{
		margin-bottom: var(--xl);
	}
`

const IntroCol = styled.div`
	grid-column: span 8;
	margin: var(--xxxxl) 0 0;
	${desktop}{
		grid-column: span 12;
		margin-bottom: var(--l);
	}
`
const Headline = styled.h3`
	margin-bottom: var(--m);
`
const JumpLinksCol = styled.div`
	grid-column: 10/13;
	position: relative;
	${desktop}{
		grid-column: span 12;
	}
`
const DownArrow = styled(Darr)`
	width: 17px;
`

const JumpLinks = styled.div`
	position: sticky;
	display: grid;
	margin-top: 64px;
	background-color: var(--bteal20);
	padding: 18px 0 0;
	${desktop}{
		margin-top: 0;
		max-width: 360px;
	}
	${mobile}{
		max-width: 100%;
	}
`

const JumpLinksHeading = styled.h5`
	padding: 0 18px 18px;
`
const JumpLink = styled.a`
	height: var(--xl);
	/* margin: var(--xs); */
	padding: var(--xxs) 18px;
	text-align: left;
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
	box-sizing: border-box;
	&:hover{
		background: var(--bteal40);
	}
	svg{
		margin-left: 10px;
	}
`

Intro.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default Intro