import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '@styles/global'
import Section from '@components/Section'
import RichText from '@components/RichText'
import Image from '@components/Image'

const PageHeader = ({ className, content }) => {
	return (
		<Wrap 
			className={className}
			fullWidth
			css={css`background-color: ${content?.background?.value};`}
		>
			<Column css={css`
				grid-column: 1/13;
				align-self: end;
			`}>
				<Box css={css`
				`}>
					<Heading className='h3'>
						{content?.headline}
					</Heading>
					<RichText content={content?.text} />
				</Box>
			</Column>
			{content?.image && 
				<HeaderImage 
					image={content?.image} 
					cover
					fullHeight
					css={css`
						grid-column: 1/13;
						margin: 0 -100px;
						${mobile}{
							margin: 0 -40px;
						}
					`}
				/>
			}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	/* > div {
		height: calc(100vh - 150px);
	}
	${mobile}{
		height: calc(100vh - 145px);
	} */
`
const Column = styled.div`
	grid-row: 1;
	z-index: 1;
`
const Box = styled.div`
	background-color: var(--beige20);
	margin: 0 0 var(--m) -60px;
	max-width: 420px;
	padding: var(--m) var(--m) var(--xl);
	box-sizing: border-box;
	${mobile}{
		max-width: 100%;
		margin: var(--m) 0;
		padding: var(--s) 30px var(--m);
	}
`
const Heading = styled.h1`
	margin-bottom: var(--m);
	${desktop}{
		font-size: 34px;
    line-height: 37.4px;
		margin-bottom: var(--s);
	}
`
const HeaderImage = styled(Image)`
	grid-row: 1;
	height: calc(100vh - 150px);
	> div {
		object-position: center;
	}
	${tablet}{
		height: calc(100vh - 130px);
	}
	${mobile}{
		height: calc(100vh - 145px);
	}
`
PageHeader.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default PageHeader